import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../utils/store";
import { validateForm } from "../../utils/validation";
import { loginRequest } from "../../requests/login-request";
import { resetPasswordRequest } from "../../requests/password-reset";
// import { checkPushSubscription } from "../../requests/push-request"

import ReplyIcon from "@mui/icons-material/Reply";
import MHLogo from "../../assets/mh.png";
import { default as s } from "./index.module.scss";
import "./fix.css";

export default function Login() {
  const navigate = useNavigate();
  const [, dispatch] = useStore();
  const [loginCredentials, setLoginCredentials] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [invalidator, setInvalidator] = useState({});
  const [readyToAPI, setReadyToAPI] = useState(false);
  const [clearPrompt, setClearPrompt] = useState({});

  const { mutate } = useMutation(loginRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: (res) => {
      dispatch({ type: "onSuccess", payload: { visible: true } });
      dispatch({ type: "updateUser", payload: res.data });
      navigate("/account", { replace: true });
      setTimeout(() => {
        dispatch({ type: "onSuccess", payload: { visible: false } });
      }, 2000);
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  const { mutate: mutateResetPassword } = useMutation(resetPasswordRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: (res) => {
      dispatch({
        type: "onSuccess",
        payload: { visible: true, message: res.message },
      });
      setTimeout(() => {
        dispatch({ type: "onSuccess", payload: { visible: false } });
      }, 2000);
      navigate("/response");
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  useEffect(() => {
    readyToAPI &&
      (forgotPassword
        ? mutateResetPassword({ email: loginCredentials.email })
        : mutate(loginCredentials));
    // eslint-disable-next-line
  }, [readyToAPI]);

  const handleFormSubmit = (flag) => {
    setClearPrompt({});
    validateForm(loginCredentials, flag, setInvalidator, setReadyToAPI);
  };

  const clearFormPrompt = (promptName) => {
    setClearPrompt({ ...clearPrompt, [promptName]: true });
  };

  const displayFormPrompt = (promptName) => {
    if (!invalidator[promptName] || clearPrompt[promptName]) return "hidden";
    else return "visible";
  };

  return forgotPassword ? (
    <div className={`login-wrapper`}>
      <div className={s["login-container"]}>
        <p className={s["login-header"]}>Zmiana hasła</p>
        <input
          onChange={(e) =>
            setLoginCredentials({
              ...loginCredentials,
              email: e.target.value ? e.target.value.toLowerCase().trim() : "",
            })
          }
          onFocus={() => clearFormPrompt("email")}
          type="text"
          placeholder="adres email"
          spellCheck="false"
        />
        <p
          className={s["form-validator-prompt"]}
          style={{ visibility: displayFormPrompt("email") }}
        >
          Niepoprawny email
        </p>
        <input style={{ visibility: "hidden" }} />
        <div className={s["change-pass-action-buttons"]}>
          <p className={s["change-password-note"]}>
            Dostaniesz email z linkiem do zmiany hasła
          </p>
          <button
            className={s["back-to-login-button"]}
            onClick={() => setForgotPassword(false)}
          >
            <ReplyIcon />
          </button>
          <button
            className={s["change-password-button"]}
            onClick={() => handleFormSubmit("reset")}
          >
            Wyślij
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={`login-wrapper`}>
      <div
        className={s["login-container"]}
        onKeyDown={(e) =>
          e.key === "Enter" && handleFormSubmit("login") && navigate("/account")
        }
      >
        <img className={s["mh-logo"]} src={MHLogo} alt="company logo" />
        <input
          onChange={(e) =>
            setLoginCredentials({
              ...loginCredentials,
              email: e.target.value ? e.target.value.toLowerCase().trim() : "",
            })
          }
          onFocus={() => clearFormPrompt("email")}
          type="text"
          placeholder="adres email"
          spellCheck="false"
          autocomplete="username"
        />
        <p
          className={s["form-validator-prompt"]}
          style={{ visibility: displayFormPrompt("email") }}
        >
          Niepoprawny email
        </p>
        <input
          onChange={(e) =>
            setLoginCredentials({
              ...loginCredentials,
              password: e.target.value,
            })
          }
          onFocus={() => clearFormPrompt("password")}
          type="password"
          placeholder="hasło"
          spellCheck="false"
          autocomplete="password"
        />
        <p
          className={s["form-validator-prompt"]}
          style={{ visibility: displayFormPrompt("password") }}
        >
          Hasło to minimum 8 znaków, w tym duża litera i cyfra
        </p>
        <p
          className={s["forgot-password-prompt"]}
          onClick={() => setForgotPassword(true)}
        >
          Nie pamiętasz hasła?
        </p>
        <button
          className={s["button-login"]}
          onClick={() => handleFormSubmit("login") && navigate("/account")}
        >
          Login
        </button>
      </div>
    </div>
  );
}
