import { Link } from "react-router-dom";
import mobileGroup from "./LandingPage/Group3.png";

export default function MainContent() {
  return (
    <>
      <div className="light">
        <div className="light-frame">
          <div className="titles">
            <h1 className="nav_app_title">
              <span>Jakość</span>
              <span>,&nbsp;</span>
              <span>Przejrzystość</span>
            </h1>
            <h3>Twój samochód, Twoja historia serwisowa</h3>
          </div>

          <p>
            Z naszą aplikacją wzorowo zbudujesz historię napraw swojego auta.
            Skorzystaj z naszej bazy zaufanych mechaników, którzy razem z Tobą
            zatroszczą się o Twój samochód!
          </p>
          <div className="main_btns">
            <Link to="/register">
              <button className="main_btn-register">Zarejestruj się</button>
            </Link>
            <button className="ghost">Zobacz demo</button>
          </div>
        </div>

        <div className="bg-circle circle">
          <img src={mobileGroup} />
        </div>
        <div className="small-text-boxes">
          <div>
            <h4>Dodaj pojazd lub poproś swojego mechanika</h4>
            <p>
              Wprowadź swoje auto, aby mieć pełną kontrolę nad jego historią
              serwisową. Możesz to zrobić sam albo za pośrednictwem swojego
              mechanika.
            </p>
          </div>
          <div>
            <h4>Wyszukaj mechanika z prawdziwymi opiniami</h4>
            <p>
              Znajdź zaufanych mechaników w swojej okolicy w kilka sekund. Po
              zakończonej naprawie oceń jakość usług. Dzięki temu powiększasz
              grono pewnych warsztatów.
            </p>
          </div>
          <div>
            <h4>Razem z mechanikiem stwórz historię swojego auta</h4>
            <p>
              Wszystkie naprawy będziesz miał w aplikacji czarno na białym, a
              nie na karteczkach pod maską. Będzie to twój as w rękawie przy
              sprzedaży.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-circle circle">
        <img src={mobileGroup} />
      </div>
    </>
  );
}
