import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { ReactComponent as Hamburger } from "../../svgs/Hamburger.svg";
import { ReactComponent as GoBack } from "../../svgs/go-back.svg";
import { useStore } from "../../utils/store";

import s from "./index.module.scss";
import t from "./tabs.module.scss";

export default function Navbar() {
  const [hamburgerOn, setHamburgerOn] = useState(false);
  const navigate = useNavigate();

  const handleLinkNavigation = (link) => {
    window.open(link, "_blank");
  };

  const SocialLinksDiv = () => {
    return (
      <div className={s.nav_social_links}>
        <FacebookIcon
          onClick={() =>
            handleLinkNavigation(
              "https://www.facebook.com/profile.php?id=61551650467936&is_tour_dismissed=true"
            )
          }
          className={s.nav_social_link}
        />
        <InstagramIcon
          onClick={() =>
            handleLinkNavigation(
              "https://www.instagram.com/motohistoria.pl/?next=%2F"
            )
          }
          className={s.nav_social_link}
        />
        <TwitterIcon
          onClick={() =>
            handleLinkNavigation("https://twitter.com/motohistoriapl")
          }
          className={s.nav_social_link}
        />
      </div>
    );
  };

  const [{ user }] = useStore();

  const goToHome = () => (!user ? navigate("/") : navigate("/account"));
  return (
    <div className={s.navbar_container}>
      <GoBack className={s.go_back} onClick={() => navigate(-1)} />
      <div className={s.nav_app_title} onClick={goToHome}>
        <span>moto</span>
        <span>Historia.pl</span>
      </div>
      <div
        className={`${s.nav_link_section_desktop} ${hamburgerOn ? s.show : ""}`}
      >
        <SocialLinksDiv />
      </div>
      {/* <Hamburger
        className={s.nav_hamburger}
        onClick={() => setHamburgerOn(true)}
      /> */}
      {/* <div className={`${t.tab_container} ${hamburgerOn ? t.show : ""}`}>
        <CloseIcon
          className={`${t.nav_menu_close_icon} ${hamburgerOn ? t.show : ""}`}
          onClick={() => setHamburgerOn(false)}
        />
        <Link
          className={`${s.nav_social_link} ${hamburgerOn ? t.show : ""}`}
          to="/about"
        >
          o nas
        </Link>
        <Link
          className={`${s.nav_social_link} ${hamburgerOn ? t.show : ""}`}
          to="/products"
        >
          produkty
        </Link>
        <Link
          className={`${s.nav_social_link} ${hamburgerOn ? t.show : ""}`}
          to="/contact"
        >
          kontakt
        </Link>
        <div
          className={`${s.nav_link_section} ${s.nav_link_section_mobile} ${
            hamburgerOn ? s.show : ""
          }`}
        >
          <SocialLinksDiv />
        </div>
      </div> */}
    </div>
  );
}
